export default {
	data() {
		return {
			windowWidth: window && window.innerWidth ? window.innerWidth : 0
		}
	},
	mounted() {
		this.onWidthChange();
		window.addEventListener('resize', this.onWidthChange)
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.onWidthChange)
	},
	methods: {
		onWidthChange() { this.windowWidth = window.innerWidth }
	}
}